import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import Table from '../../modals/Table'
import UsersObject from '../../objects/UsersObject'
import API from '../../helpers/api'
import { updateStates } from '../../helpers/helpers'

class UsersView extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // user data loading state
            usr_loading: true,
            // api dataset
            usr_dataset: null,
            // account table records
            acc_records: [],
            // otp loading state
            otp_loading: true,
            // otp table records
            otp_records: [],
            // otp record filters
            otp_filters: {
                page: 1,
                limit: 6,
                sort: 'createdDate',
                sort_mode: 'ASC'
            },
            // time state to auto update
            time: null
        }
        // method binds
        this.close = this.close.bind(this)
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // component on mount
    componentDidMount() {
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getUsersById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({
                usr_dataset: data,
                acc_records: data.otherAccounts,
                usr_loading: false
            }, () => {
                // load otp table
                this.loadOTPTable()
            })
        })
    }
    // component on update
    componentDidUpdate() {
        // check with time
        if (this.props.time !== this.state.time) {
            // check otp loading
            if (this.state.otp_loading === false) {
                // update otp table
                this.loadOTPTable()
            }
            // remember last update time
            this.setState({ time: this.props.time })
        }
    }
    // method to load table 
    async loadOTPTable(event = null) {
        // set loading flag
        await this.update({ otp_loading: true })
        // check event
        if (event) {
            // update filters
            await this.update({ otp_filters: event.value })
        }
        // get user msisdn id
        const msisdn = this.state.usr_dataset.msisdn
        // load prepaid table data
        const data = await API.getUsersOTPHistorybyId(msisdn, this.state.otp_filters)
        // update records and states
        await this.update({
            otp_loading: false,
            otp_records: data.otpHistories,
            otp_filters: { total: data.total }
        })
    }
    // methd to close
    close() {
        // navigate to table page
        this.props.history.push('../')
    }
    // method to render
    render() {
        // return page dom
        return (
            <>
                {/* user info */}
                <Form
                    loading={this.state.usr_loading}
                    dataset={this.state.usr_dataset}
                    datamap={UsersObject}
                    disable={true}
                />
                {/* other accounts */}
                <div className="page-title">Other Accounts</div>
                <Table
                    loading={this.state.usr_loading}
                    records={this.state.acc_records}
                    columns={[
                        {
                            name: 'Name',
                            type: 'text',
                            data: item => item.name,
                            side: 'left',
                            size: 180
                        },
                        {
                            name: 'Mobile',
                            type: 'text',
                            data: item => item.msisdn
                        },
                        {
                            name: 'Type',
                            type: 'text',
                            data: item => item.type
                        },
                        {
                            name: 'Payment Type',
                            type: 'text',
                            data: item => item.paymentType
                        },
                        {
                            name: 'Email',
                            type: 'text',
                            data: item => item.email,
                            side: 'left'
                        }
                    ]}
                />
                {/* otp history */}
                <div className="page-title">OTP History</div>
                <Table
                    loading={this.state.otp_loading}
                    records={this.state.otp_records}
                    filters={this.state.otp_filters}
                    onevent={event => this.loadOTPTable(event)}
                    columns={[
                        {
                            name: 'Create Date',
                            type: 'text',
                            data: item => item.createdDate,
                            size: 180
                        },
                        {
                            name: 'OTP',
                            type: 'text',
                            data: item => item.otp
                        },
                        {
                            name: 'Status',
                            type: 'text',
                            data: item => item.status
                        },
                        {
                            name: 'Type',
                            type: 'text',
                            data: item => item.type
                        },
                        {
                            name: 'Comment',
                            type: 'text',
                            data: item => item.comment || '-'
                        }
                    ]}
                />
                <button
                    disabled={this.props.usr_loading || this.props.otp_loading}
                    className="form-modal-form-button close"
                    onClick={this.close}>
                    Close
                </button>
            </>
        )
    }
}

export default withRouter(UsersView)