import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import { UsersOtherAccountObject } from '../../objects/UsersObject'
import Confirm from '../../modals/Confirm'
import API from '../../helpers/api'
import { updateStates } from '../../helpers/helpers'

class UsersOtherAccountUpdate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api dataset
            dataset: null,
            // account list
            acclist: []
        }
        // method binds
        this.callback = this.callback.bind(this)
        this.close = this.close.bind(this)
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // component on mount
    componentDidMount() {
        // get item id
        const item_id = this.props.match.params.account_id
        // request item data
        API.getUsersOtherAccountById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({
                dataset: data,
                acclist: data.otherAccounts,
                loading: false
            })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            // confirm submit
            Confirm('Are you sure to update this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.state.loading = true
                    // get item id
                    const item_id = this.props.match.params.account_id
                    // reuest update
                    API.updateUsersOtherAccountById(item_id, event.value).then(() => {
                        // stop loading
                        this.state.loading = false
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.close()
                    }).catch(() => {
                        // stop loading
                        this.state.loading = false
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard changes?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.close()
                }
                // destroy modal
                data.target.destroy()
            })
        }
    }
    // methd to close
    close() {
        // get user id
        const user_id = this.props.match.params.id
        // navigate to table page
        this.props.history.push('../../' + user_id)
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={UsersOtherAccountObject}
                onevent={this.callback}
            />
        )
    }
}

export default withRouter(UsersOtherAccountUpdate)