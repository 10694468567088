const UsersObject = [
    {
        name: 'Name',
        node: 'name',
        type: 'text',
        lock: true
    },
    {
        name: 'Reg. Date',
        node: 'registrationDate',
        type: 'text',
        lock: true
    },
    {
        name: 'Contact Number',
        node: 'msisdn',
        type: 'text',
        lock: true
    },
    // {
    //     name: 'Email',
    //     node: 'email',
    //     type: 'email'
    // }
]

export const UsersOtherAccountObject = [
    {
        name: 'Mobile',
        node: 'msisdn',
        type: 'text',
        lock: true
    },
    {
        name: 'Type',
        node: 'type',
        type: 'text',
        lock: true
    },
    {
        name: 'Payment Type',
        node: 'paymentType',
        type: 'text',
        lock: true
    },
    {
        type: 'separator'
    },
    {
        name: 'Name',
        node: 'name',
        type: 'text'
    },
    // {
    //     name: 'Email',
    //     node: 'email',
    //     type: 'email'
    // }
]

export default UsersObject