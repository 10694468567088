import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import InfoPagesObject from '../../objects/InfoPagesObject'
import Confirm from '../../modals/Confirm'
import API from '../../helpers/api'
import { getImage } from '../../helpers/helpers'
import Alert from '../../modals/Alert'

class InfoPagesCreate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: false
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            if (
              !event.value.header?.length ||
              !event.value.description?.length
            ) {
              Alert("Header and Description sections cannot be empty").then(
                (data) => {
                  data.target.destroy();
                }
              );
              return;
            }
            // confirm submit
            Confirm('Are you sure to submit this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.setState({ loading: true })
                    // request create
                    API.createInfoPages(event.value).then(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.props.history.push('../')
                    }).catch(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.props.history.push('../')
                }
                // destroy modal
                data.target.destroy()
            })
        } else if (event.type === 'update') {
            if (event.node === 'image') {
                // get image from file
                getImage(event.data).then(image => {
                    // check image size
                    if (image.width === 1180 && image.height === 664) {
                        // upload image
                        API.uploadFile(event.data).then(data => {
                            // set id on form
                            event.fill({ image: data.id })
                        })
                    } else {
                        // alert image size
                        Alert('Image size should be 1180px of width and 664px of height').then(data => {
                            // destroy modal
                            data.target.destroy()
                        })
                        // restore old image
                        event.fill({ image: event.old_data })
                    }
                })
            }
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                datamap={InfoPagesObject}
                onevent={this.callback}
            />
        )
    }
}

export default withRouter(InfoPagesCreate)