import { getDropdownValue } from "../helpers/helpers"

const PrepaidPlansObject = [
  {
    name: "Plan Name",
    node: "plan_name",
    type: "text",
    requ: true,
  },
  {
    name: "Plan Type",
    node: "plan_type",
    type: "dropdown",
    list: [],
    bind: {
      set: getDropdownValue,
      get: (data, mdata) => ({ id: getDropdownValue(data, mdata) }),
    },
  },
  {
    name: "Plan Group",
    node: "plan_group",
    type: "dropdown",
    list: ["Daily Plans", "Weekly Plans", "Monthly Plans", "Hourly Plans"],
    data: "Daily Plans",
  },
  {
    type: "separator",
  },
  {
    name: "Plan Identification Code",
    node: "plan_identifier_code",
    type: "text",
    requ: true,
  },
  {
    name: "Plan Subscription Code",
    node: "plan_subscription_code",
    type: "text",
    requ: true,
  },
  {
    name: "Plan Price Unit",
    node: "plan_price_unit",
    type: "text",
    data: "VT",
  },
  {
    type: "separator",
  },
  {
    name: "Plan Expiry Hours",
    node: "plan_expiry",
    type: "object",
    list: [
      {
        name: "Value",
        node: "value",
        type: "number",
        bind: { set: (data) => data, get: (data) => parseInt(data) },
      },
      {
        name: "Unit",
        node: "unit",
        type: "dropdown",
        list: ["hours", "days"],
        data: "hours",
      },
    ],
    bind: {
      map: (data, odata) => {
        if (odata && "id" in odata) {
          data.id = odata.id;
        }
        return data;
      },
    },
  },
  {
    type: "separator",
  },
  {
    name: "Plan Price",
    node: "plan_price",
    type: "text",
    unit: "VT",
    requ: true,
  },
  {
    name: "Plan Addons",
    node: "plan_addons",
    type: "textarea",
  },
  {
    name: "Plan Description",
    node: "plan_description",
    type: "textarea",
  },
  {
    type: "separator",
  },
  {
    name: "Plan Data Roll Over",
    node: "plan_data_rollover",
    type: "checkbox",
    data: false,
  },
  {
    name: "Plan Data RollOver Limit",
    node: "plan_data_rollover_limit",
    type: "text",
    unit: "MB",
  },
  {
    name: "Plan Auto Renewal",
    node: "plan_autorenewal",
    type: "checkbox",
    data: false,
  },
  {
    name: "Plan Exist Check",
    node: "plan_exist_check",
    type: "checkbox",
    data: false,
  },
  {
    name: "Plan Gifting",
    node: "plan_gifting",
    type: "checkbox",
    data: false,
  },
  {
    type: "separator",
  },
  {
    name: "Plan Bonus Status",
    node: "plan_bonus_status",
    type: "text",
  },
  {
    name: "Plan Bonus Description",
    node: "plan_bonus_description",
    type: "textarea",
  },
  {
    type: "separator",
  },
  {
    name: "Plan Bonus Data",
    node: "plan_bonus_data",
    type: "text",
    requ: true,
  },
  {
    name: "Plan Bonus Data Expiry Hours",
    node: "plan_bonus_data_expiry_hours",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "H",
  },
  {
    name: "Plan Bonus Minute",
    node: "plan_bonus_mins",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "Min",
  },
  {
    name: "Plan Bonus Minute Expiry Hours",
    node: "plan_bonus_mins_expiry_hours",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "H",
  },
  {
    name: "Plan Bonus SMS",
    node: "plan_bonus_sms",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
  },
  {
    name: "Plan Bonus SMS Expiry Hours",
    node: "plan_bonus_sms_expiry_hours",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "H",
  },
  {
    name: "Plan Bonus IDD Mins",
    node: "plan_bonus_idd_mins",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "Min",
  },
  {
    name: "Plan Bonus IDD Mins Expiry",
    node: "plan_bonus_idd_mins_expiry",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
    unit: "Min",
  },
  {
    type: "separator",
  },
  {
    name: "Plan Payment Method",
    node: "plan_payment_method",
    type: "text",
  },
  {
    name: "Plan Brand Type",
    node: "plan_brand_type",
    type: "text",
  },
  {
    name: "Plan Time Based",
    node: "plan_time_based",
    type: "number",
    bind: { set: (data) => data, get: (data) => parseInt(data) },
  },
  {
    type: "separator",
  },
  {
    name: "Plan Start Time",
    node: "plan_start_time",
    type: "time",
  },
  {
    name: "Plan End Time",
    node: "plan_end_time",
    type: "time",
  },
  {
    type: "separator",
  },
  {
    name: "Limited Plugins",
    node: "limited_plugins",
    type: "array",
    list: [
      {
        name: "Value",
        node: "value",
        type: "text",
      },
      {
        name: "Unit",
        node: "unit",
        type: "dropdown",
        list: ["GB", "MB", "Mins", "SMS", "IDD"],
        data: "GB",
      },
      {
        name: "Type",
        node: "type",
        type: "dropdown",
        list: ["Data", "Voice", "SMS", "IDD"],
        data: "Data",
      },
    ],
    bind: {
      map: (data, odata) => {
        if (odata && "id" in odata) {
          data.id = odata.id;
        }
        return data;
      },
    },
  },
  {
    type: "separator",
  },
  // {
  //     name: 'Plan Enable',
  //     node: 'plan_enabled',
  //     type: 'checkbox',
  //     data: false
  // },
  {
    name: "Active",
    node: "status",
    type: "switch",
    data: "Active",
    bind: {
      set: (data) => data === "Active",
      get: (data) => (data ? "Active" : "Inactive"),
    },
  },
];

export default PrepaidPlansObject