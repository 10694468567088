import axios from "axios"
import { getLocal, setLocal } from "./helpers"

// create axios instance for directus
const instance_drct = axios.create({ baseURL: process.env.REACT_APP_API_URL })
// create axios instance for backend
const instance_wbff = axios.create({ baseURL: process.env.REACT_APP_BFF_URL })

// method to get headers
const getHeaders = async (...names) => {
    // headers object
    const headers = { 'Content-Type': 'application/json' }
    // get user data
    const udata = getLocal('user-data', {})
    // auth token
    if (names.includes('auth_token')) {
        // check token expiration
        if (udata.expires_time - Date.now() < 0) {
            // request new token
            const data = await API.refreshToken(udata.refresh_token)
            // set new token
            udata.access_token = data.access_token
            // decode token data
            data.data = JSON.parse(atob(data.access_token.split('.')[1]))
            // calculate expires time
            data.expires_time = Date.now() + data.expires
            // set data on local
            setLocal('user-data', data)
        }
        // set bearer token header
        headers['Authorization'] = `Bearer ${udata.access_token}`
    }
    // multipart form data
    if (names.includes("multipart")) {
      headers["Content-Type"] = "multipart/form-data";
    } else if (names.includes("basic_token")) {
      // get basic token
      const token =
        process.env.REACT_APP_BFF_USR + ":" + process.env.REACT_APP_BFF_PWD;
      // set basic token header
      headers["Authorization"] = "Basic " + window.btoa(token);
    }
    // return headers
    return headers
}

// object to query parameters string
export const toQueryParams = obj => {
    // return key mapping
    return Object.keys(obj)
        .map((key) => {
            // get value
            const val = obj[key]
            // combine key with values
            return key + '=' + (val !== null ? val : '')
            // join params
        })
        .join('&')
}

// object to form data
const toFormData = (obj) => {
    // create new form data
    const data = new FormData()
    // for each object item
    Object.keys(obj).forEach((key) => {
        data.append(key, obj[key])
    })
    // return form data
    return data
}

// method to get search params string from query
const getSearchParams = obj => {
    // return ampped string
    return Object.keys(obj).map(key => {
        if (key === 'sort') {
            // sort value
            const val = obj[key]
            // sort and sort mode
            return key + '=' + (obj.sort_mode === 'DESC' ? '-' : '') + val
        } else if (key === 'sort_mode') {
            // get filter count
            return 'meta=filter_count'
        } else if (key === 'fields') {
            // join fields
            return key + '=' + obj[key].join(',')
        } else if (key === 'total') {
            // ignore total key
            return ''
        } else {
            // common parameter
            return key + '=' + obj[key]
        }
    }).join('&')
}

// api object
const API = {}

// method to mockup
API.mockup = async (endpoint, query) => {
    // create request path with query
    const path = endpoint + '.json?' + toQueryParams(query)
    // fetch request data
    const resp = await fetch(path, {
        headers: await getHeaders('auth_token')
    })
    // parse data
    const data = await resp.json()
    // resolve with delay
    return new Promise(resolve => setTimeout(() => resolve(data), 600))
}

// method to login
API.login = async (email, password) => {
    // request login
    return instance_drct.post('/auth/login', {
        email, password, mode: 'json'
    })
}

// method to refresh token
API.refreshToken = async refresh_token => {
    // response object
    const data = { resp: null }
    // try request
    try {
        // request new token
        data.resp = await instance_drct.post('/auth/refresh', {
            refresh_token, mode: 'json'
        })
    } catch (_err) {
        // clear storage
        setLocal('user-data', null)
        // navigate to root
        window.location.href = '/'
    }
    // return data
    return data.resp.data.data
}

// method to get user data
API.getUserById = async user_id => {
    // request user data
    const resp = await instance_drct.get(`/users/${user_id}`, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to get role data
API.getRoleById = async role_id => {
    // request role data
    const resp = await instance_drct.get(`/roles/${role_id}`, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to upload file
API.uploadFile = async file => {
    // get form data
    const data = toFormData({ file })
    // request upload
    const resp = await instance_drct.post('/files', data, {
        headers: await getHeaders('auth_token', 'multipart'),
    })
    // return data
    return resp.data.data
}

// =========================== preload types ===========================

// method to get faq types
API.getFAQTypes = async () => {
    // get request path
    const path = '/items/question_type'
    // request faq
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to get plan types
API.getPlanTypes = async () => {
    // get request path
    const path = '/items/plan_type'
    // request faq
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// =========================== info pages ===========================

// method to get info pages
API.getInfoPages = async query => {
    // get request path
    const path = '/items/info_pages/?' + getSearchParams(query)
    // request info pages
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set info pages status
API.setInfoPagesStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/info_pages/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete info pages
API.deleteInfoPages = async id => {
    // request delete
    return instance_drct.delete('/items/info_pages/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create info pages
API.createInfoPages = async data => {
    // request post
    return instance_drct.post('/items/info_pages', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get info pages by id
API.getInfoPagesById = async id => {
    // request data
    const resp = await instance_drct.get('/items/info_pages/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update info pages by id
API.updateInfoPagesById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/info_pages/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== carousal items ===========================

// method to get carousal items
API.getCarousalItems = async query => {
    // get request path
    const path = '/items/carousel_item/?' + getSearchParams(query)
    // request carousal items
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set carousal items status
API.setCarousalItemsStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/carousel_item/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete carousal items
API.deleteCarouselItems = async id => {
    // request delete
    return instance_drct.delete('/items/carousel_item/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create carousal items
API.createCarouselItems = async data => {
    // request post
    return instance_drct.post('/items/carousel_item', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get carousal items by id
API.getCarouselItemsById = async id => {
    // request data
    const resp = await instance_drct.get('/items/carousel_item/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update carousal items by id
API.updateCarouselItemsById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/carousel_item/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== faq ===========================

// method to get faq
API.getFAQ = async query => {
    // get request path
    const path = '/items/questions/?' + getSearchParams(query)
    // request faq
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set faq status
API.setFAQStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/questions/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete faq
API.deleteFAQ = async id => {
    // request delete
    return instance_drct.delete('/items/questions/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create faq
API.createFAQ = async data => {
    // request post
    return instance_drct.post('/items/questions', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get faq by id
API.getFAQById = async id => {
    // request data
    const resp = await instance_drct.get('/items/questions/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update faq by id
API.updateFAQById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/questions/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== locations ===========================

// method to get locations
API.getLocations = async query => {
    // get request path
    const path = '/items/location/?' + getSearchParams(query)
    // request locations
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set locations status
API.setLocationsStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/location/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete locations
API.deleteLocations = async id => {
    // request delete
    return instance_drct.delete('/items/location/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create locations
API.createLocations = async data => {
    // request post
    return instance_drct.post('/items/location', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get locations by id
API.getLocationsById = async id => {
    // request data
    const resp = await instance_drct.get('/items/location/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update locations by id
API.updateLocationsById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/location/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== promotions ===========================

// method to get promotions
API.getPromotions = async query => {
    // get request path
    const path = '/items/packages/?' + getSearchParams(query)
    // request promotions
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set promotions status
API.setPromotionsStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/packages/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete promotions
API.deletePromotions = async id => {
    // request delete
    return instance_drct.delete('/items/packages/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create promotions
API.createPromotions = async data => {
    // request post
    return instance_drct.post('/items/packages', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get promotions by id
API.getPromotionsById = async id => {
    // request data
    const resp = await instance_drct.get('/items/packages/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update promotions by id
API.updatePromotionsById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/packages/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== borrow credit ===========================

// method to get borrow credit
API.getBorrowCredit = async query => {
    // get request path
    const path = '/items/borrow_credit/?' + getSearchParams(query)
    // request borrow credit
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set borrow credit status
API.setBorrowCreditStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/borrow_credit/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete borrow credit
API.deleteBorrowCredit = async id => {
    // request delete
    return instance_drct.delete('/items/borrow_credit/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create borrow credit
API.createBorrowCredit = async data => {
    // request post
    return instance_drct.post('/items/borrow_credit', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get borrow credit by id
API.getBorrowCreditById = async id => {
    // request data
    const resp = await instance_drct.get('/items/borrow_credit/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update borrow credit by id
API.updateBorrowCreditById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/borrow_credit/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== prepaid plans ===========================

// method to get prepaid plans
API.getPrepaidPlans = async query => {
    // get request path
    const path = '/items/pre_paid_plans/?' + getSearchParams(query)
    // request prepaid plans
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set prepaid plans status
API.setPrepaidPlansStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/pre_paid_plans/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete prepaid plans
API.deletePrepaidPlans = async id => {
    // request delete
    return instance_drct.delete('/items/pre_paid_plans/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create prepaid plans
API.createPrepaidPlans = async data => {
    // request post
    return instance_drct.post('/items/pre_paid_plans', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get prepaid plan by id
API.getPrepaidPlansById = async id => {
    // request data
    const resp = await instance_drct.get('/items/pre_paid_plans/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update prepaid plan by id
API.updatePrepaidPlansById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/pre_paid_plans/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== postpaid plans ===========================

// method to get postpaid plans
API.getPostpaidPlans = async query => {
    // get request path
    const path = '/items/post_paid_plans/?' + getSearchParams(query)
    // request postpaid plans
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set postpaid plans status
API.setPostpaidPlansStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/post_paid_plans/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete postpaid plans
API.deletePostpaidPlans = async id => {
    // request delete
    return instance_drct.delete('/items/post_paid_plans/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create postpaid plans
API.createPostpaidPlans = async data => {
    // request post
    return instance_drct.post('/items/post_paid_plans', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get postpaid plan by id
API.getPostpaidPlansById = async id => {
    // request data
    const resp = await instance_drct.get('/items/post_paid_plans/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update postpaid plan by id
API.updatePostpaidPlansById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/post_paid_plans/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== asset items ===========================

// method to get asset items
API.getAssetItems = async query => {
    // get request path
    const path = '/items/asset_item/?' + getSearchParams(query)
    // request asset items
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to delete asset items
API.deleteAssetItems = async id => {
    // request delete
    return instance_drct.delete('/items/asset_item/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create asset items
API.createAssetItems = async data => {
    // request post
    return instance_drct.post('/items/asset_item', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get asset items by id
API.getAssetItemsById = async id => {
    // request data
    const resp = await instance_drct.get('/items/asset_item/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update asset items by id
API.updateAssetItemsById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/asset_item/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== app version ===========================

// method to get app versions
API.getAppVersions = async query => {
    // get request path
    const path = '/items/app_version/?' + getSearchParams(query)
    // request app version
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to delete app versions
API.deleteAppVersions = async id => {
    // request delete
    return instance_drct.delete('/items/app_version/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create app versions
API.createAppVersions = async data => {
    // request post
    return instance_drct.post('/items/app_version', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get app version by id
API.getAppVersionsById = async id => {
    // request data
    const resp = await instance_drct.get('/items/app_version/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update app version by id
API.updateAppVersionsById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/app_version/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== error codes ===========================

// method to get error codes
API.getErrorCodes = async query => {
    // get request path
    const path = '/items/error_codes/?' + getSearchParams(query)
    // request error codes
    const resp = await instance_drct.get(path, {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data
}

// method to set error codes status
API.setErrorCodesStatus = async (id, status) => {
    // request status change
    return instance_drct.patch('/items/error_codes/' + id, { status }, {
        headers: await getHeaders('auth_token')
    })
}

// method to delete error codes
API.deleteErrorCodes = async id => {
    // request delete
    return instance_drct.delete('/items/error_codes/' + id, {
        headers: await getHeaders('auth_token')
    })
}

// method to create error codes
API.createErrorCodes = async data => {
    // request post
    return instance_drct.post('/items/error_codes', data, {
        headers: await getHeaders('auth_token')
    })
}

// method to get error codes by id
API.getErrorCodesById = async id => {
    // request data
    const resp = await instance_drct.get('/items/error_codes/' + id + '?fields=*.*', {
        headers: await getHeaders('auth_token')
    })
    // return data
    return resp.data.data
}

// method to update error codes by id
API.updateErrorCodesById = async (id, data) => {
    // request update
    return await instance_drct.patch('/items/error_codes/' + id, data, {
        headers: await getHeaders('auth_token')
    })
}

// =========================== transactions ===========================

// object to query parameters string from web bff
export const toBFFQueryParams = (obj, api) => {
    // output object
    const output = {
        // pagination query
        page: obj.page - 1, size: obj.limit,
        // sorting query
        sort: obj.sort + ',' + obj.sort_mode.toLowerCase()
    }
    // check search value
    if (api === 'users' && 'search' in obj && obj.search !== '') {
        // get search type
        const type = obj.search.includes('@')
            ? 'EMAIL' : /[a-zA-Z]/g.test(obj.search)
                ? 'NAME' : 'MSISDN'
        // set users search on output
        Object.assign(output, { type, value: obj.search })
    } else if(api === 'payments' && 'search' in obj && obj.search !== '') {
        // set payments search on output
        Object.assign(output, { transactionId: obj.search })
    }
    // return key mapping
    return Object.keys(output)
        .map((key) => {
            // get value
            const val = output[key];
            // combine key with values
            return key + '=' + (val !== null ? val : '');
            // join params
        })
        .join('&');
};

// method to get transactions
API.getTransactions = async query => {
    // create request path with query
    const path = '/admin/payments?' + toBFFQueryParams(query, 'payments')
    // request transactions
    const resp = await instance_wbff.get(path, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// =========================== users ===========================

// method to get users
API.getUsers = async query => {
    // create request path with query
    const path = '/admin/users?' + toBFFQueryParams(query, 'users')
    // request users
    const resp = await instance_wbff.get(path, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// method to get users by id
API.getUsersById = async id => {
    // request data
    const resp = await instance_wbff.get('/admin/users/' + id, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// method to get users otp history by id
API.getUsersOTPHistorybyId = async (msisdn, query) => {
    // create request params string
    const params = toBFFQueryParams(query, 'users')
    // request data
    const resp = await instance_wbff.get(`/admin/otp/${msisdn}/history?${params}`, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// method to get users other account by id
API.getUsersOtherAccountById = async id => {
    // request data
    const resp = await instance_wbff.get('/users/account/' + id, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// method to update users by id
API.updateUsersById = async (id, data) => {
    // create request path with query
    const path = '/users/' + id + '?' + toQueryParams({ email: data.email })
    // request update
    return await instance_wbff.patch(path, {}, {
        headers: await getHeaders('basic_token')
    })
}

// method to delete users other account by id
API.deleteUsersOtherAccount = async id => {
    // request data
    const resp = await instance_wbff.delete('/users/account/' + id, {
        headers: await getHeaders('basic_token')
    })
    // return data
    return resp.data
}

// method to update users other account by id
API.updateUsersOtherAccountById = async (id, data) => {
    // create request query
    const query = toQueryParams({ email: data.email, name: data.name })
    // request update
    return await instance_wbff.patch('/users/account/' + id + '?' + query, {}, {
        headers: await getHeaders('basic_token')
    })
}

export default API