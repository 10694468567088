import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from '../../modals/Form'
import CarouselItemsObject from '../../objects/CarouselItemsObject'

import API from '../../helpers/api'
import Preloader from '../../helpers/preloader'
import { getPlansByType, updateDropdownList } from '../../helpers/helpers'

class CarouselItemsView extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api datamap
            datamap: CarouselItemsObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // preload plan types
        await Preloader('PlanTypes', 'PrepaidPlans')
        // load dropdown values
        //updateDropdownList(CarouselItemsObject, 'action', 'PlanTypes')
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        const data = await API.getCarouselItemsById(item_id)
        this.setState({
          dataset: data,
          datamap: CarouselItemsObject,
          loading: false,
        });
        // filter reference list
        // this.filterReferenceList(data.action ? data.action.id : null, () => {
        //     // stop loading
        //     this.setState({ dataset: data, datamap: CarouselItemsObject, loading: false })
        // })
    }
    // method to filter reference list
    // filterReferenceList(type, callback) {
    //     // get reference item
    //     const item = CarouselItemsObject.find(item => item.node === 'reference')
    //     // filter and set reference list from full list
    //     item.list = getPlansByType('PrepaidPlans', type)
    //     // set default data
    //     item.data = item.list[0].data.toString()
    //     // update datamap
    //     this.setState({ datamap: CarouselItemsObject }, () => callback(item.list))
    // }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'close') {
            // navigate to table page
            this.props.history.push('../')
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={this.state.datamap}
                onevent={this.callback}
                disable={true}
            />
        )
    }
}

export default withRouter(CarouselItemsView)