import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import Table from '../../modals/Table'
import UsersObject from '../../objects/UsersObject'
import Confirm from '../../modals/Confirm'
import API from '../../helpers/api'
import { updateStates } from '../../helpers/helpers'

class UsersUpdate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api dataset
            dataset: null,
            // account list
            acclist: []
        }
        // method binds
        this.callback = this.callback.bind(this)
        this.updateOtherAccount = this.updateOtherAccount.bind(this)
        this.deleteOtherAccount = this.deleteOtherAccount.bind(this)
        this.close = this.close.bind(this)
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // load dataset component on mount
    componentDidMount() { this.load() }
    // method to load dataset
    async load() {
        // set loading flag
        await this.update({ loading: true })
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getUsersById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({
                dataset: data,
                acclist: data.otherAccounts,
                loading: false
            })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            // confirm submit
            Confirm('Are you sure to update this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.state.loading = true
                    // get item id
                    const item_id = this.props.match.params.id
                    // reuest update
                    API.updateUsersById(item_id, event.value).then(() => {
                        // stop loading
                        this.state.loading = false
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.props.history.push('../')
                    }).catch(() => {
                        // stop loading
                        this.state.loading = false
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard changes?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.props.history.push('../')
                }
                // destroy modal
                data.target.destroy()
            })
        }
    }
    // method to update other account
    updateOtherAccount(item) {
        // get current path
        const path = this.props.history.location.pathname
        // navigate to item edit
        this.props.history.push(path + '/other-account/' + item.accountId)
    }
    // method to delete other account
    deleteOtherAccount(item) {
        // request user confirmation
        Confirm('Are you sure you want to delete this item?').then(async data => {
            // lock modal
            data.target.lock()
            // check response
            if (data.response === 'action') {
                // set loading flag
                await this.update({ loading: true })
                // request item delete
                await API.deleteUsersOtherAccount(item.accountId)
                // destroy modal
                data.target.destroy()
                // reload data
                await this.load()
            } else {
                // destroy modal
                data.target.destroy()
            }
        })
    }
    // methd to close
    close() {
        // navigate to table page
        this.props.history.push('../')
    }
    // method to render
    render() {
        // return page dom
        return (
            <>
                {/* general details */}
                <div className="page-title">General Details</div>
                <Form
                    loading={this.state.loading}
                    dataset={this.state.dataset}
                    datamap={UsersObject}
                    onevent={this.callback}
                />
                {/* other accounts */}
                <div className="page-title">Other Accounts</div>
                <Table
                    loading={this.state.loading}
                    records={this.state.acclist}
                    columns={[
                        {
                            name: 'Name',
                            type: 'text',
                            data: item => item.name,
                            side: 'left',
                            size: 180
                        },
                        {
                            name: 'Mobile',
                            type: 'text',
                            data: item => item.msisdn
                        },
                        {
                            name: 'Type',
                            type: 'text',
                            data: item => item.type
                        },
                        {
                            name: 'Payment Type',
                            type: 'text',
                            data: item => item.paymentType
                        },
                        {
                            name: 'Email',
                            type: 'text',
                            data: item => item.email,
                            side: 'left'
                        },
                        {
                            type: 'button',
                            data: 'Update',
                            call: this.updateOtherAccount,
                            size: 80
                        },
                        {
                            type: 'button',
                            data: 'Delete',
                            call: this.deleteOtherAccount,
                            size: 80
                        }
                    ]}
                />
                <button
                    disabled={this.props.usr_loading || this.props.otp_loading}
                    className="form-modal-form-button close"
                    onClick={this.close}>
                    Close
                </button>
            </>
        )
    }
}

export default withRouter(UsersUpdate)