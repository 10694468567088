const InfoPagesObject = [
  {
    name: "Header",
    node: "header",
    type: "array",
    list: [
      {
        name: "Language",
        node: "languge",
        type: "dropdown",
        data: "EN",
        list: ["EN", "BI", "FR"],
      },
      {
        name: "Message",
        node: "message",
        type: "text",
        requ: true
      },
    ],
    bind: {
      map: (data, odata) => {
        if (odata && "id" in odata) {
          data.id = odata.id;
        }
        return data;
      },
    },
  },
  {
    type: "separator",
  },
  {
    name: "Description",
    node: "description",
    type: "array",
    list: [
      {
        name: "Language",
        node: "languge",
        type: "dropdown",
        data: "EN",
        list: ["EN", "BI", "FR"],
      },
      {
        name: "Message",
        node: "message",
        type: "text",
        requ: true
      },
    ],
    bind: {
      map: (data, odata) => {
        if (odata && "id" in odata) {
          data.id = odata.id;
        }
        return data;
      },
    },
  },
  {
    type: "separator",
  },
  {
    name: "Image",
    node: "image",
    type: "image",
    data: { id: "" },
    hint: "(1180 x 664)",
    bind: {
      set: (data) => (data ? data.id : ""),
      get: (data) => (data ? { id: data } : null),
    },
    requ: true
  },
  {
    type: "separator",
  },
  {
    name: "Active",
    node: "status",
    type: "switch",
    data: "Active",
    bind: {
      set: (data) => data === "Active",
      get: (data) => (data ? "Active" : "Inactive"),
    },
  },
];

export default InfoPagesObject