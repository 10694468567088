import React from 'react'
import { withRouter } from 'react-router-dom'
import API from '../../helpers/api'
import { updateStates } from '../../helpers/helpers'
import Table from '../../modals/Table'

class Transactions extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // table records
            records: [],
            // record filters
            filters: {
                search: '',
                page: 1,
                limit: 8,
                sort: 'id',
                sort_mode: 'DESC'
            }
        }
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // load table on mount
    componentDidMount() { this.loadTable() }
    // method to load table 
    async loadTable(event = null) {
        // set loading flag
        await this.update({ loading: true })
        // check event
        if (event) {
            // update filters
            await this.update({ filters: event.value })
        }
        // load prepaid table data
        const data = await API.getTransactions(this.state.filters)
        // update records and states
        await this.update({
          loading: false,
          records: data.accountTransactions,
          filters: { total: data.total },
        });
    }
    // method to render
    render() {
        // return page dom
        return (
            <Table
                loading={this.state.loading}
                records={this.state.records}
                filters={this.state.filters}
                onevent={event => this.loadTable(event)}
                columns={[
                    {
                        name: 'Transaction Id',
                        type: 'text',
                        sort: 'transactionId',
                        data: item => item.id || '-',
                    },
                    {
                        name: 'Mobile',
                        type: 'text',
                        data: item => item.msisdn,
                        size: 150
                    },
                    {
                        name: 'Amount',
                        type: 'text',
                        data: item => item.amount,
                        sort: 'amount',
                        side: 'right',
                        size: 50
                    },
                    {
                        name: 'Created Date',
                        type: 'text',
                        data: item => item.dateTime,
                        sort: 'createdDate'
                    },
                    {
                        name: 'State',
                        type: 'text',
                        data: item => item.status
                    },
                ]}
            />
        )
    }
}

export default withRouter(Transactions)