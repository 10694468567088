import React from 'react'
import { withRouter } from 'react-router-dom'
import API from '../../helpers/api'
import { updateStates } from '../../helpers/helpers'
import Table from '../../modals/Table'

class Users extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // table records
            records: [],
            // record filters
            filters: {
                search: '',
                page: 1,
                limit: 8,
                sort: 'id',
                sort_mode: 'ASC'
            }
        }
        // method binds
        this.reviewItem = this.reviewItem.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // load table on mount
    componentDidMount() { this.loadTable() }
    // method to load table 
    async loadTable(event = null) {
        // set loading flag
        await this.update({ loading: true })
        // check event
        if (event) {
            // update filters
            await this.update({ filters: event.value })
        }
        // load prepaid table data
        const data = await API.getUsers(this.state.filters)
        // update records and states
        await this.update({
            loading: false,
            records: data.users,
            filters: { total: data.total }
        })
    }
    // method to review item
    reviewItem(item) {
        // navigate to item view
        this.props.history.push(this.props.path + '/view/' + item.userId)
    }
    // method to update item
    updateItem(item) {
        // navigate to item edit
        this.props.history.push(this.props.path + '/edit/' + item.userId)
    }
    // method to render
    render() {
        // return page dom
        return (
            <Table
                loading={this.state.loading}
                records={this.state.records}
                filters={this.state.filters}
                onevent={event => this.loadTable(event)}
                columns={[
                    {
                        name: 'Id',
                        type: 'text',
                        data: item => item.userId,
                        sort: 'id',
                        size:160
                    },
                    {
                        name: 'Mobile',
                        type: 'text',
                        data: item => item.msisdn,
                        sort: 'msisdn',
                        size: 150
                    },
                    {
                        name: 'Name',
                        type: 'text',
                        data: item => item.name,
                        side: 'left',
                        sort: 'name'
                    },
                    {
                        name: 'Email',
                        type: 'text',
                        data: item => item.email,
                        side: 'left',
                        sort: 'email'
                    },
                    {
                        type: 'button',
                        data: 'View',
                        call: this.reviewItem,
                        size: 80
                    },
                    {
                        type: 'button',
                        data: 'Update',
                        call: this.updateItem,
                        size: 80
                    }
                ]}
            />
        )
    }
}

export default withRouter(Users)