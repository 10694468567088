import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from '../../modals/Form'
import Confirm from '../../modals/Confirm'
import Alert from '../../modals/Alert'
import CarouselItemsObject from '../../objects/CarouselItemsObject'

import API from '../../helpers/api'
import Preloader from '../../helpers/preloader'
import { getImage, getPlansByType, updateDropdownList } from '../../helpers/helpers'

class CarouselItemsUpdate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api datamap
            datamap: CarouselItemsObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // preload plan types
        await Preloader('PlanTypes', 'PrepaidPlans')
        // load dropdown values
        //updateDropdownList(CarouselItemsObject, 'action', 'PlanTypes')
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        const data = await API.getCarouselItemsById(item_id)
        this.setState({
          dataset: data,
          datamap: CarouselItemsObject,
          loading: false,
        });
        // filter reference list
        // this.filterReferenceList(data.action ? data.action.id : null, () => {
        //     // stop loading
        //     this.setState({ dataset: data, datamap: CarouselItemsObject, loading: false })
        // })
    }
    // method to filter reference list
    // filterReferenceList(type, callback) {
    //     // get reference item
    //     const item = CarouselItemsObject.find(item => item.node === 'reference')
    //     // filter and set reference list from full list
    //     item.list = getPlansByType('PrepaidPlans', type)
    //     // set default data
    //     item.data = item.list[0].data.toString()
    //     // update datamap
    //     this.setState({ datamap: CarouselItemsObject }, () => callback(item.list))
    // }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            // confirm submit
            Confirm('Are you sure to update this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.setState({ loading: true })
                    // get item id
                    const item_id = this.props.match.params.id
                    // request create
                    API.updateCarouselItemsById(item_id, event.value).then(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.props.history.push('../')
                    }).catch(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard changes?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.props.history.push('../')
                }
                // destroy modal
                data.target.destroy()
            })
        } else if (event.type === 'update') {
            // check node
            // if (event.node === 'action') {
            //     // filter reference list
            //     this.filterReferenceList(event.data, list => {
            //         // set first item
            //         event.fill({ reference: list.length > 0 ? list[0].data.toString() : '' })
            //     })
            // } 
            
            if (event.node === 'image') {
                // get image from file
                getImage(event.data).then(image => {
                    // check image size
                    if (image.width === 1180 && image.height === 664) {
                        // upload image
                        API.uploadFile(event.data).then(data => {
                            // set id on form
                            event.fill({ image: data.id })
                        })
                    } else {
                        // alert image size
                        Alert('Image size should be 1180px of width and 664px of height').then(data => {
                            // destroy modal
                            data.target.destroy()
                        })
                        // restore old image
                        event.fill({ image: event.old_data })
                    }
                })
            }
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={this.state.datamap}
                onevent={this.callback}
            />
        )
    }
}

export default withRouter(CarouselItemsUpdate)